"use client";

import { useEffect, useState } from "react";
import useAppContext from "@/src/components/appContext/useAppContext";
import { useServerAction } from "../useServerAction";
import useLocalStorageCache from "../useLocalStorageCache";
import { LocalStorageKey } from "../../enums/common";
import { DEFAULT_REVALIDATE_TIME } from "../../constants/cache";
import { CountryOption } from "../../types/country";
import { getLocaleCountriesAction } from "@/src/actions/common/getLocaleCountriesAction";

export function useCountryOptions() {
  const { ctx } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState<CountryOption[]>([]);

  useEffect(() => {
    if (ctx.countries) {
      const list = ctx.countries?.list;

      setCountry(list ?? []);
      setLoading(false);
    }
  }, [ctx.googleCategory]);

  return {
    loading,
    country,
  };
}

// Only for root use.
export function useLocaleCountriesProvider() {
  const { setContext, ctx } = useAppContext();
  const currLang = ctx.lang;
  const isAuthenticated = !!ctx.user?.id;

  const getLocaleCountriesList = useServerAction(getLocaleCountriesAction);

  const { value, isLoading, lastUpdated, fetchAndUpdateCache } =
    useLocalStorageCache({
      key: LocalStorageKey.CountryList,
      revalidateTime: DEFAULT_REVALIDATE_TIME,
      shouldRevalidate: isAuthenticated,
      fetchValue: async () => {
        const response = await getLocaleCountriesList({ lang: currLang });
        const result = response?.data?.result;

        if (result?.length) {
          return {
            list: result,
            lang: currLang,
          };
        }

        return null;
      },
    });

  const { list, lang: cachedLang } = value ?? {};

  useEffect(() => {
    if (cachedLang !== currLang && !isLoading && isAuthenticated) {
      fetchAndUpdateCache({ force: cachedLang !== currLang });
    } else if (list?.length) {
      setContext((previousValue) => ({
        ...previousValue,
        countries: {
          list,
          lastUpdated,
        },
      }));
    }
  }, [list]);
}
