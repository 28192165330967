"use client";

import { useFeatureFlag } from "./useFeatureFlag";

export function useClientLogger() {
  const { isApiDebugEnabled } = useFeatureFlag();

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logger: (...params: any) => {
      if (isApiDebugEnabled) {
        // eslint-disable-next-line no-console
        console.log(...params);
      }
    },
  };
}
