"use client";

import { BffApiResponse } from "../types/api/common";
import { useClientLogger } from "./useClientLogger";

const sleep = (m: number) =>
  new Promise((r) => {
    setTimeout(r, m);
  });

export function useServerAction<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Args extends any[],
  Ret,
  Err,
>(
  fn: (...args: Args) => Promise<BffApiResponse<Ret, Err>>
): (...args: Args) => Promise<BffApiResponse<Ret, Err>> {
  const { logger } = useClientLogger();

  return async (...args: Args) => {
    await sleep(0);
    const result = await fn(...args);

    logger({
      params: args,
      result,
    });

    return result;
  };
}
